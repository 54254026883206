:root {
  --toastify-color-error: rgb(253, 237, 237);
  --toastify-icon-color-warning: var(--toastify-color-light);
  --toastify-color-success: rgb(243, 249, 245);
  --toastify-toast-min-height: 48px;
}

.Toastify__toast--error {
  background-color: var(--toastify-color-error) !important;
  color: #272727 !important;
}

.Toastify__toast--success {
  background-color: var(--toastify-color-success) !important;
  color: #272727 !important;
} 

.Toastify__toast-icon svg {
  fill: var(--toastify-color-light) !important;
}

.Toastify__toast--success .Toastify__toast-icon svg {
  fill: #6DB98B !important;
}

.Toastify__toast--error .Toastify__toast-icon svg {
  fill: rgb(211, 47, 47) !important;
}

.Toastify__toast-icon {
  color: currentColor !important;
}

.Toastify__toast-body {
  font-family: "SF Pro Display", sans-serif !important;
  font-size: 16px !important;
  font-weight: 400 !important;
  line-height: 1.2 !important;
  margin: 0;
}
