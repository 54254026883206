.MuiPickersPopper-root .MuiPickersPopper-paper {
  box-shadow: none;
  border: 1px solid #D6D6D6;
  border-radius: 8px;
}

.MuiDayCalendar-header .MuiDayCalendar-weekDayLabel {
  font-size: 12px;
  font-weight: 500;
  color: #C4C4C4;
  margin-bottom: 8px;
}

.MuiDayCalendar-weekContainer .MuiButtonBase-root {
  font-size: 12px;
}

.MuiDayCalendar-weekContainer .MuiButtonBase-root:not(.Mui-disabled) {
  border-radius: 4px !important;
  background-color: #F5F5F5;
  color: #272727;
}

.MuiDayCalendar-weekContainer .MuiButtonBase-root.MuiPickersDay-today {
  background-color: #F2EFFF;
  border-color: #8777D9;
}

.MuiDayCalendar-weekContainer .MuiButtonBase-root.Mui-selected {
  background-color: #8777D9;
  color: #fff;
}
